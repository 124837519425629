import { Component } from '@angular/core';
import templateString from './appTemplate.html'
// import { Intercom } from 'ng-intercom';


@Component({
  selector: 'utility',
  template: templateString  
})


export class AppComponent {
  constructor(
    // public intercom: Intercom
  ){}

  mobileNavigationOpen: boolean = false;
  copyrightYear: number = new Date().getFullYear();
  
  ngOnInit() {
    // this.intercom.boot({
    //   app_id: "tcvjsnrb",
    //   // Supports all optional configuration.
    //   widget: {
    //     "activator": "#intercom" 
    //   }
    // });
  }

}


