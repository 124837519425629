import { Component } from '@angular/core';
import templateString from './confirmPasswordTemplate.html'
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { FormsModule } from '@angular/forms';
import { Title }     from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import {Router} from '@angular/router';

export interface User {
  password: string;
  token: string;
}

@Component({
  selector: 'reset-password',
  template: templateString  
})


export class ConfirmPasswordComponent {
  constructor(private httpClient: HttpClient, 
              private toastr: ToastrService,
              private titleService: Title,
              private route: ActivatedRoute,
              private router: Router){}  

  runningResetPassword: boolean = false;
  user: User = {password: '', token: ""};  

  ngOnInit(){
    this.titleService.setTitle( "TheoremReach - Confirm New Password" );
    $("#scene").parallax();
    this.route.queryParams.subscribe(params => {
      this.user.token = params['token'];
    });  

  }

 
  confirmPassword() {
    if (this.runningResetPassword == true){
      return false;
    }    
    this.runningResetPassword = true;  
    this.httpClient.post("/api/internal/reset_password", this.user).subscribe(
      (response) => {
        this.runningResetPassword = false; 
        this.router.navigate(['/sign_in']);
      },
      (error) => {
        console.log(error);
        this.toastr.error(error.error.error, 'Cannot Reset Password');        
        this.runningResetPassword = false;  
        }
      );    
  }    


}
