import { Component } from '@angular/core';
import templateString from './signUpTemplate.html'
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { FormsModule } from '@angular/forms';
import { Title }     from '@angular/platform-browser';



@Component({
  selector: 'sign-up',
  template: templateString
})


export class SignUpComponent {
  constructor(private httpClient: HttpClient, 
              private toastr: ToastrService,
              private titleService: Title){}  


  runningSignUp: boolean = false;
  runningRegister: boolean = false;
  user: object = {email: '', first_name:'', last_name:'', password:'', company_name:''};  

  ngOnInit(){
    this.titleService.setTitle( "TheoremReach - Sign Up" );
    $("#scene").parallax();
  }
  
  signUp() {
    if (this.runningRegister == true){
      return false;
    }
    this.runningRegister = true;      
    this.httpClient.post("/api/internal/registrations", this.user).subscribe(
      (response) => {
        this.runningRegister = false; 
        this.user = {email: '', first_name:'', last_name:'', password:'', company_name:''};  
        this.toastr.success("You must confirm your email before creating surveys", 'Check Your Email'); 
      },
      (error) => {
  
        this.toastr.error(error.error.error, 'Cannot Register');        
        this.runningRegister = false;  
        }
      );    
  }    

}
