import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router'; 
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { ParallaxModule, ParallaxConfig } from 'ngx-parallax';
import 'hammerjs';
// import { IntercomModule } from 'ng-intercom';
import { GtagModule } from 'angular-gtag';


//App Defined
import { AppComponent } from './app.component';
import { SignInComponent } from './signIn/signIn.component';
import { SignUpComponent } from './signUp/signUp.component';
import { ResetPasswordComponent } from './resetPassword/resetPassword.component';
import { ConfirmPasswordComponent } from './confirmPassword/confirmPassword.component';
import { ParallaxComponent } from './parallax/parallax.component';


const appRoutes: Routes = [
  { path: '', redirectTo: '/sign_in', pathMatch: 'full'},

  { path: 'sign_in', component: SignInComponent },
  { path: 'sign_up', component: SignUpComponent },
  { path: 'reset_password', component: ResetPasswordComponent },
  { path: 'confirm_password', component: ConfirmPasswordComponent },
  { path: '**', redirectTo: '/sign_in', pathMatch: 'full' }

]

@NgModule({
  declarations: [
    AppComponent,
    SignInComponent,
    ParallaxComponent,
    SignUpComponent,
    ResetPasswordComponent,
    ConfirmPasswordComponent

  ],
  imports: [BrowserModule,FormsModule, RouterModule.forRoot(appRoutes), HttpClientModule, BrowserAnimationsModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
      closeButton: true
    }),
    ParallaxModule,
    // IntercomModule.forRoot({appId: "tcvjsnrb", updateOnRouterChange: true }),
    GtagModule.forRoot({ trackingId: 'UA-156436848-1', trackPageviews: true })],
  providers: [Title],
  bootstrap: [AppComponent]
})

export class AppModule { }



