import { Component } from '@angular/core';
import templateString from './resetPasswordTemplate.html'
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { FormsModule } from '@angular/forms';
import { Title }     from '@angular/platform-browser';
import {Router} from '@angular/router';



@Component({
  selector: 'reset-password',
  template: templateString  
})


export class ResetPasswordComponent {
  constructor(private httpClient: HttpClient, 
              private toastr: ToastrService,
              private titleService: Title,
              private router: Router){}  

  
  runningResetPassword: boolean = false;
  resetPasswordSent: boolean = false;
  user: object = {email: ''};  

  ngOnInit(){
    this.titleService.setTitle( "TheoremReach - Reset Password" );
    $("#scene").parallax();
  }
  
  resetPassword() {
    if (this.runningResetPassword == true){
      return false;
    }    
    this.runningResetPassword = true;  
    this.httpClient.post("/api/internal/start_reset_password", this.user).subscribe(
      (response) => {
        this.runningResetPassword = false; 
        this.resetPasswordSent = true;
      },
      (error) => {
        console.log(error);
        this.toastr.error(error.error.error, 'Cannot Reset Password');        
        this.runningResetPassword = false;  
        }
      );    
  }    


}
