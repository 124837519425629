import { Component, Inject, PLATFORM_ID } from "@angular/core";
import templateString from "./signInTemplate.html";
import { HttpClient } from "@angular/common/http";
import { ToastrService } from "ngx-toastr";
import { Title } from "@angular/platform-browser";
import { isPlatformBrowser, Location } from "@angular/common";

@Component({
  selector: "sign-in",
  template: templateString
})
export class SignInComponent {
  constructor(
    private httpClient: HttpClient,
    private toastr: ToastrService,
    private titleService: Title,
    private location: Location,
    @Inject(PLATFORM_ID) private platformId: any
  ) {}

  user: { email: string; password: string; } = { email: "", password: "" };
  runningSignIn: boolean = false;

  ngOnInit() {
    this.titleService.setTitle("TheoremReach - Sign In");
    if (isPlatformBrowser(this.platformId)) {
      $("#scene").parallax();
    }
  }

  logIn() {
    if (this.runningSignIn == true) {
      return false;
    }
    this.runningSignIn = true;
    this.httpClient.post("/api/internal/sessions", this.user).subscribe(
      response => {
        this.toastr.success("", "Logging In..");
        this.runningSignIn = false;
        console.log("fash");
        console.log(response);
        //this.location.go("/surveys");
        window.location.href = "/surveys";
      },
      error => {
        console.log(error);
        this.toastr.error(error.error.error, "Cannot Log In");
        this.runningSignIn = false;
      }
    );
  }
}
