import { Component } from '@angular/core';
import templateString from './parallaxTemplate.html'

@Component({
  selector: 'parallax',
  template: templateString  
})


export class ParallaxComponent {
  constructor(){}  


}
