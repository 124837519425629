import './polyfills.ts';

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { gtag } from '../gtag';

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic().bootstrapModule(AppModule);
  gtag('js', new Date());
  gtag('config', 'UA-156436848-1', { 'send_page_view': false });

});
